export const HALF_SECOND = 500;

export const ONE_SECOND = HALF_SECOND * 2;

export const ONE_MINUTE = ONE_SECOND * 60;

export const ONE_HOUR = ONE_MINUTE * 60;

export const ONE_DAY = ONE_HOUR * 24;

export const ONE_WEEK = ONE_DAY * 7;

export const ONE_MONTH = ONE_DAY * 30;

export const ONE_YEAR = ONE_DAY * 365;
