import classNames from "classnames";
import { ButtonHTMLAttributes, ReactNode } from "react";

import Loading from "../Loading";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  size?: "medium" | "small" | "large" | "x-small" | "md-afya" | "default";
  variant?:
    | "primary"
    | "secondary"
    | "afya"
    | "default"
    | "gray"
    | "whiteBlue"
    | "danger"
    | "tertiary";
  full?: boolean;
  icon?: ReactNode;
  tooltip?: string;
}

export default function Button(props: Props) {
  const { children, isLoading, disabled, className, size, variant, full, tooltip, ...rest } = props;

  const baseClass = classNames("font-semibold leading-6 flex justify-center items-center", {
    "w-full": full,
    "cursor-not-allowed": disabled,
  });

  const variants = {
    primary:
      "bg-neutral-900 text-neutral-0 disabled:bg-neutral-200 hover:bg-afya-3 hover:text-black disabled:text-neutral-400",
    secondary:
      "bg-neutral-0 text-afya-gray-600 border border-afya-lightgray-100 hover:bg-afya-lightgray-100 hover:border-afya-lightgray-200 hover:text-black disabled:border-neutral-300 disabled:text-neutral-300",
    afya: "bg-afya-aqua-200 text-neutral-0 disabled:bg-afya-lightgray-100 hover:bg-afya-aqua-300 disabled:text-neutral-400",
    default:
      "bg-afya-4 hover:bg-afya-5 text-neutral-0 disabled:bg-neutral-200 disabled:text-neutral-400 font-normal",
    gray: "bg-gray-300 hover:bg-gray-100 text-neutral-900  border-neutral-900  hover:text-neutral-400 disabled:border-neutral-300 disabled:text-neutral-300",
    whiteBlue: "bg-transparent font-medium text-afya-aqua-300 hover:bg-afya-aqua-100",
    danger:
      "bg-afya-red-100 text-white hover:bg-afya-red-200 disabled:bg-afya-lightgray-100 disabled:text-neutral-400",
    tertiary: "bg-transparent text-afya-red-100 border-0",
  };

  const variantSizes = {
    large: "py-2 px-4 h-[56px] text-base rounded-lg",
    medium: "py-2 px-4 h-[44px] text-base rounded-lg",
    small: "py-2 px-4 h-[40px] text-sm rounded-md",
    "x-small": "py-2 px-3 h-[36px] text-sm rounded-md",
    ["md-afya"]: "py-2 px-4 h-[40px] w-[99px] text-base rounded-lg",
    default: "py-2 px-3 h-[40px] w-[169px] text-base rounded-md",
  };

  const loadingVariantColor =
    variant === "primary" || disabled ? "fill-neutral-900" : "fill-neutral-0";

  const btnClass = classNames(
    baseClass,
    variantSizes[size],
    variants[variant],
    variantSizes,
    className,
  );
  return (
    <button {...rest} disabled={disabled} className={`${btnClass}`} title={tooltip}>
      {isLoading ? <Loading color={loadingVariantColor} /> : children}
    </button>
  );
}
