"use-client";
import { useCallback, useEffect, useRef } from "react";

import { useSegment } from "@src/contexts/SegmentContext";
import { useWindowName } from "@src/hooks/useWindowName";

export interface EventProperties {
  solutionshub_id: string;
  sessionStart?: string;
  name?: string;
  email?: string;
  customer?: string;
  phone?: string;
  department?: string;
  sessionId?: string;
}

interface valuesProps {
  solutionshub_id?: string;
  sessionId?: string;
  email?: string;
  idIssue?: string;
  feedbackAprovacao?: string;
  approved?: string;
  adCheckbox?: boolean;
  formQueroNovasSolucoes?: {
    box1: string;
    box2: string;
    avaliacao: string;
  };
  powerBiEventName?: string;
  reportName?: string;
  reportPage?: string;
  timeToRender?: number;
  originEvent?: string;
  bookmarkDisplayname?: string;
  bookmarkName?: string;
  errorMessage?: string;
  errorCode?: string;
  filterTitle?: string;
  filterType?: string;
  filteredTableName?: string;
  filteredColumnName?: string;
  filteredDataPoint?: string;
  multiselection?: boolean;
}

export const useAnalytics = () => {
  const { segment, locationUser, sessionStart } = useSegment();
  const { windowName, updateWindowName } = useWindowName();

  const windowNameRef = useRef(windowName);

  useEffect(() => {
    windowNameRef.current = windowName;
  }, [windowName]);

  const trackGenericEvent = useCallback(
    (eventType: string, eventName: string, properties?: valuesProps) => {
      if (segment) {
        const textoForm = properties?.formQueroNovasSolucoes;
        const payload = {
          sessionId: windowNameRef.current,
          sessionStart: sessionStart,
          eventName: properties?.powerBiEventName || eventName,
          reportName: properties?.reportName,
          reportsPage: properties?.reportPage,
          timeToRender: properties?.timeToRender,
          originEvent: properties?.originEvent,
          bookmarkDisplayname: properties?.bookmarkDisplayname,
          bookmarkName: properties?.bookmarkName,
          errorMessage: properties?.errorMessage,
          errorCode: properties?.errorCode,
          filterTitle: properties?.filterTitle,
          filterType: properties?.filterType,
          filteredTableName: properties?.filteredTableName,
          filteredColumnName: properties?.filteredColumnName,
          filteredDataPoint: properties?.filteredDataPoint,
          multiselection: properties?.multiselection,
          formQueroNovasSolucoes: textoForm,
          solutionshub_id: properties?.solutionshub_id,
          email: properties?.email,
          adCheckbox: properties?.adCheckbox,
          idIssue: properties?.idIssue,
          feedbackAprovacao: properties?.feedbackAprovacao,
          approved: properties?.approved,
        };

        const context = {
          location: {
            latitude: locationUser?.latitude,
            longitude: locationUser?.longitude,
          },
        };

        if (eventType === "page") {
          segment.page(eventType, payload, context);
        } else {
          segment.track(eventType, payload, context);
        }
      }
    },
    [segment, sessionStart, locationUser],
  );

  const trackEvent = useCallback(
    (eventName: string, properties?: valuesProps) => {
      if (segment) {
        const textoForm = properties?.formQueroNovasSolucoes;

        segment.track(
          eventName,
          {
            ...properties,
            sessionId: windowName,
            sessionStart,
            formQueroNovasSolucoes: textoForm,
          },
          {
            context: {
              location: {
                latitude: locationUser?.latitude,
                longitude: locationUser?.longitude,
              },
            },
          },
        );
      }
    },
    [segment, sessionStart, windowName, locationUser],
  );

  const identifyUser = useCallback(
    async (eventName: string, traits: EventProperties) => {
      if (segment) {
        await segment.identify(traits, {
          context: {
            location: {
              latitude: locationUser?.latitude,
              longitude: locationUser?.longitude,
            },
          },
          sessionId: windowName,
          solutionshub_id: traits.solutionshub_id,
        });
        trackLogin(eventName, traits);
      }
    },
    [segment, trackEvent, locationUser, windowName],
  );

  const trackPageView = useCallback(
    (eventName: string, properties?: valuesProps) =>
      trackGenericEvent("page_view", eventName, properties),
    [trackGenericEvent],
  );

  const trackButtonClick = useCallback(
    (eventName: string, properties?: valuesProps) =>
      trackGenericEvent("button_clicked", eventName, properties),
    [trackGenericEvent],
  );

  const trackLogin = useCallback(
    (eventName: string, properties?: valuesProps) =>
      trackGenericEvent("signed_in", eventName, properties),
    [trackGenericEvent],
  );

  const trackLogout = useCallback(
    (eventName: string, properties?: valuesProps) =>
      trackGenericEvent("signed_out", eventName, properties),
    [trackGenericEvent],
  );

  return {
    identifyUser,
    trackEvent,
    trackPageView,
    trackButtonClick,
    trackLogin,
    trackLogout,
    windowName,
  };
};
