import { zodResolver } from "@hookform/resolvers/zod";
import Close from "@src/assets/icons/Close";
import LabelFieldInput from "@src/components/blocks/Fields/LabelFieldInput";
import Button from "@src/components/common/Button";
import Checkbox from "@src/components/common/Checkbox";
import LinkHilight from "@src/components/common/LinkHilight";
import Modal from "@src/components/modal";
import { CLICK_BOX_PRIVACY, OPTION_EMAIL, PRIVACY_PAGE } from "@src/constants/constant";
import { findClientInfos, findIp, termsAccept, TermsData } from "@src/services/users";
import { ExceptionsConstants } from "@src/shared/enums/exceptions";
import { selectUserLoading } from "@src/store/slices/users/selectors";
import { useAnalytics } from "@src/utils/analytics";
import { toast } from "@src/utils/toast";
import { AcceptTermsValidationSchema } from "@src/utils/validationForm";
import React, { useEffect, useState } from "react";
import { deviceDetect } from "react-device-detect";
import { useForm } from "react-hook-form";

import styles from "./privacy-pop-up-login.module.scss";

import { useAppDispatch, useAppSelector } from "@src/store/hooks";

interface Props {
  onClose: (e: boolean, existingCpf: boolean) => void;
  onAccept: () => void;
  userId: string;
  isPrivacyAccepted: boolean;
  cpfRegistered: string;
  phoneRegistered: string;
  show: boolean;
}

export const PrivacyPopUpMessageLogin = (props: Props) => {
  const { onClose, onAccept, userId, show, isPrivacyAccepted, cpfRegistered, phoneRegistered } =
    props;
  const isPersonalDataRegistered = !!cpfRegistered && !!phoneRegistered;
  const {
    register,
    resetField,
    handleSubmit,
    clearErrors,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(AcceptTermsValidationSchema(isPersonalDataRegistered, isPrivacyAccepted)),
    mode: "onChange",
  });
  const dispatch = useAppDispatch();
  const { trackPageView, trackButtonClick } = useAnalytics();
  const isLoadingUsers = useAppSelector(selectUserLoading);
  const [blockButton, setBlockButton] = useState(false);

  useEffect(() => {
    setBlockButton(!isValid);
  }, [isValid]);

  useEffect(() => {
    setValue("phone", phoneRegistered);
    setValue("cpf", cpfRegistered);
  }, [cpfRegistered, phoneRegistered]);

  useEffect(() => {
    if (show && userId) {
      trackPageView(PRIVACY_PAGE, { solutionshub_id: userId });
    }
  }, [show, userId]);

  const onSubmit = async (data: any) => {
    try {
      const privacyPayload: TermsData = {
        user: userId,
      };

      if (!isPersonalDataRegistered) {
        privacyPayload.newCpf = data?.cpf;
        privacyPayload.newPhone = data?.phone;
      }

      if (!isPrivacyAccepted) {
        const { osName: so } = deviceDetect(window.navigator.userAgent);
        const { city, state, lat, lon } = await dispatch(findClientInfos());
        const { ip } = await dispatch(findIp());

        privacyPayload.so = so;
        privacyPayload.lat = lat;
        privacyPayload.lon = lon;
        privacyPayload.city = city;
        privacyPayload.state = state;
        privacyPayload.emailMkt = data?.emailMkt;
        privacyPayload.ip = ip;
      }

      await dispatch(termsAccept(privacyPayload));

      onAccept();
      onClose(true, false);

      toast({
        type: "success",
        title: "Cadastro concluído com sucesso!",
        message:
          "Suas informações foram salvas com segurança. Agora você já pode acessar todas as funcionalidades do Afya Campaign.",
      });
    } catch (err: any) {
      console.error(err);
      onClose(
        false,
        err.response?.data?.message
          ? err.response?.data?.message === ExceptionsConstants.EXISTING_DOCUMENT
          : false,
      );
    }
  };

  const handleCancel = () => {
    clearErrors();
    resetField("terms");
    resetField("emailMkt");
    onClose(false, false);
  };

  const handleChangeTerms = (event: any) => {
    if (event.target.checked) {
      trackButtonClick(CLICK_BOX_PRIVACY);
    }
  };

  return (
    <>
      {show && (
        <Modal isCloseIcon={false} width="w-[550px]">
          <div className={`${styles.privacy__login_pop_up} p-6`}>
            <div className="absolute right-3 cursor-pointer" onClick={handleCancel}>
              <Close />
            </div>
            <h1
              className={`text-2xl mb-6 font-bold font-nunito ${
                !isPersonalDataRegistered ? "text-center" : ""
              }`}
            >
              {!isPersonalDataRegistered
                ? "Complete seu cadastro"
                : "Aceite os Termos de Uso e  Política de Privacidade"}
            </h1>
            {!isPersonalDataRegistered && !isPrivacyAccepted ? (
              <p className="font-thin mb-6 px-8 text-afya-gray-600">
                Para continuar, complete seu cadastro informando os dados abaixo e aceite os Termos
                de uso e Políticas de privacidade.
              </p>
            ) : !isPersonalDataRegistered && isPrivacyAccepted ? (
              <p className="font-thin mb-6 px-6 text-afya-gray-600">
                Para continuar, complete seu cadastro informando os dados abaixo.
              </p>
            ) : isPersonalDataRegistered && !isPrivacyAccepted ? (
              <p className="font-thin mb-10 text-afya-gray-600">
                Para continuar utilizando nosso serviço, por favor, leia e aceite nossos Termos de
                nossa Política de Privacidade. Estes documentos explicam nossos compromissos com
                você e seus direitos ao utilizar nossa plataforma.
              </p>
            ) : null}

            {!isPersonalDataRegistered && (
              <div className="flex flex-col gap-5 px-14">
                <div className="flex flex-col gap-1">
                  <LabelFieldInput
                    label={<p className="text-base">CPF</p>}
                    id="cpf"
                    name="cpf"
                    type="text"
                    required={false}
                    placeholder="000.000.000-00"
                    mask="999.999.999-99"
                    control={control}
                    {...register("cpf")}
                    value={cpfRegistered || ""}
                    error={Boolean(errors.cpf)}
                  />
                  <span
                    className={`text-xs mt-2 ${errors.cpf ? "text-red-500" : "text-neutral-400 "}`}
                  >
                    {errors.cpf && errors.cpf?.message
                      ? `${errors.cpf?.message}`
                      : "Seu CPF é solicitado para segurança das informações compartilhadas."}
                  </span>
                </div>
                <div className="flex flex-col gap-0.5">
                  <LabelFieldInput
                    label={<p className="text-base">Celular</p>}
                    id="phone"
                    name="phone"
                    type="text"
                    mask="(99) 99999-9999"
                    required={false}
                    control={control}
                    value={phoneRegistered || ""}
                    placeholder="(00) 00000-0000"
                    {...register("phone")}
                    error={Boolean(errors.phone)}
                  />
                  <span
                    className={`text-xs mt-2 ${
                      errors.phone ? "text-red-500" : "text-neutral-400 "
                    }`}
                  >
                    {errors.phone && errors.phone?.message ? `${errors.phone?.message}` : ""}
                  </span>
                </div>
              </div>
            )}

            {!isPrivacyAccepted && (
              <div
                className={`flex flex-col gap-5 mt-8 ${!isPersonalDataRegistered ? "px-6" : ""}`}
              >
                <Checkbox
                  id="terms"
                  name="terms"
                  className="ml-2"
                  onChange={handleChangeTerms}
                  label={
                    <p className="text-base font-thin">
                      Li e concordo com os{" "}
                      <LinkHilight href="/use-terms"> Termos de Uso </LinkHilight> e{" "}
                      <LinkHilight href="/privacy-policy"> Políticas de privacidade </LinkHilight>
                    </p>
                  }
                  {...register("terms")}
                />
                <Checkbox
                  id="emailMkt"
                  name="emailMkt"
                  onChange={e =>
                    trackButtonClick(OPTION_EMAIL, {
                      adCheckbox: e.target.checked,
                      solutionshub_id: userId,
                    })
                  }
                  label={
                    <span className="font-thin text-base">
                      Eu aceito receber e-mail com novidades e atualizações
                    </span>
                  }
                  {...register("emailMkt")}
                />
              </div>
            )}

            <div className=" flex justify-end mt-6" role="group">
              <Button className="mr-3" variant="secondary" size="md-afya" onClick={handleCancel}>
                Cancelar
              </Button>

              <Button
                size="md-afya"
                variant="afya"
                onClick={handleSubmit(onSubmit)}
                disabled={blockButton}
                isLoading={isLoadingUsers}
                {...(blockButton && {
                  tooltip:
                    "Para continuar é necessário preencher os campos corretamente e \naceitar os Termos de uso e Políticas de privacidade.",
                })}
              >
                Aceitar
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
