import { z } from "zod";

import {
  REGEX_DIGIT,
  REGEX_PHONE,
  REGEX_SPECIAL_CHARACTER,
  REGEX_UPPERCASE_AND_LOWERCASE,
} from "./regexPatterns";

export type createUserFormData = {
  name?: string;
  cpf?: string;
  phone?: string;
  department?: string;
  customer?: string;
  email?: string;
  avatar?: string;
  roles: string;
  password?: string;
  confirmPassword?: string;
};

export type forgotPasswordData = {
  email?: string;
};

const cpfSchema = (isRequired: boolean) =>
  z
    .union([z.string(), z.literal("")])
    .optional()
    .refine(value => {
      if (!isRequired && !value) return true;
      if (!value) return false;
      const replacedDoc = value.replace(/\D/g, "");
      return replacedDoc.length === 11;
    }, "Informe um CPF válido")
    .superRefine((value, ctx) => {
      if (!value) return;

      if (
        value
          .replace(/\.|-/gm, "")
          .split("")
          .every(char => char === value[0])
      ) {
        ctx.addIssue({ code: "custom", message: "Informe um CPF válido" });
      }

      const calcularDigitoVerificador = (cpfParcial: string) => {
        const soma = cpfParcial.split("").reduce((acc, digit, index) => {
          const peso = cpfParcial.length + 1 - index;
          return acc + parseInt(digit) * peso;
        }, 0);
        const digito = 11 - (soma % 11);
        return digito > 9 ? 0 : digito;
      };

      const document = value.replace(/\.|-/gm, "");
      const digitoVerificador1 = calcularDigitoVerificador(document.slice(0, 9));
      const digitoVerificador2 = calcularDigitoVerificador(
        document.slice(0, 9) + digitoVerificador1,
      );

      if (
        parseInt(document[9]) !== digitoVerificador1 ||
        parseInt(document[10]) !== digitoVerificador2
      ) {
        ctx.addIssue({ code: "custom", message: "Informe um CPF válido" });
      }
    });

export const baseValidationSchema = z.object({
  name: z.string({ required_error: "Campo obrigatório" }).min(1, { message: "Campo obrigatório" }),
  cpf: cpfSchema(false).transform(value => value || ""),
  phone: z
    .string()
    .optional()
    .transform(value => value || "")
    .refine(value => value === undefined || value === "" || REGEX_PHONE.test(value), {
      message: "Informe um telefone válido",
    }),
  customer: z
    .string({ required_error: "Campo obrigatório" })
    .min(1, { message: "Campo obrigatório" })
    .refine(value => value !== "Selecione uma das opções", {
      message: "Campo obrigatório",
    }),
  department: z
    .string({ required_error: "Campo obrigatório" })
    .min(1, { message: "Campo obrigatório" })
    .refine(value => value !== "Selecione uma das opções", {
      message: "Campo obrigatório",
    }),
  email: z
    .string({ required_error: "Campo obrigatório" })
    .email({ message: "Informe um email válido" }),
  avatar: z
    .union([
      z.string().includes("image/jpeg", { message: "Selecione uma foto válida" }),
      z.string().includes("image/png", { message: "Selecione uma foto válida" }),
      z.string().includes("image/svg", { message: "Selecione uma foto válida" }),
    ])
    .optional(),
  roles: z
    .string()
    .nonempty("O perfil do usuário é obrigatório.")
    .refine(value => value !== "Selecione uma das opções", {
      message: "O perfil do usuário é obrigatório",
    }),
});

export const EmailValidationSchema = z.object({
  email: z
    .string({ required_error: "Campo obrigatório" })
    .min(1, { message: "Campo obrigatório" })
    .email({ message: "Informe um email Válido" }),
});

export const BaseCustomerValidationSchema = z.object({
  name: z.string({ required_error: "Campo obrigatório" }).min(1, { message: "Campo obrigatório" }),
  document: z
    .string({ required_error: "Campo obrigatório" })
    .min(1, { message: "Campo obrigatório" }),
  logo: z.string().optional(),
});

export const CreateCustomerValidationSchema = BaseCustomerValidationSchema;

export const CustomerValidationSchema = BaseCustomerValidationSchema.extend({
  emailToNotification: z
    .string()
    .optional()
    .superRefine((value, ctx) => {
      if (!value || value.trim() === "") return;

      const emails = value
        .split(";")
        .map(email => email.trim())
        .filter(email => email !== "");

      const uniqueEmails = new Set(emails);
      if (uniqueEmails.size !== emails.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Por favor, remova os e-mails duplicados e tente novamente.",
        });
        return;
      }

      emails.forEach(email => {
        const emailValidation = emailSchema.safeParse(email);
        if (!emailValidation.success) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Por favor, insira e-mails válidos separados por ponto e vírgula.",
          });
        }
      });
    }),
});

const emailSchema = z
  .string({ required_error: "Campo obrigatório" })
  .min(1, { message: "Campo obrigatório" })
  .email({ message: "Informe um email Válido" });

const passwordValidation = z.object({
  password: z
    .string()
    .min(8, { message: "A senha deve ter no mínimo 8 caracteres" })
    .regex(REGEX_UPPERCASE_AND_LOWERCASE, { message: "A senha deve conter pelo menos uma letra" })
    .regex(REGEX_DIGIT, { message: "A senha deve conter pelo menos um número" })
    .regex(REGEX_SPECIAL_CHARACTER, {
      message: "A senha deve conter pelo menos um caractere especial",
    })
    .optional()
    .or(z.literal("")),
  confirmPassword: z.string().optional(),
});

export const createOrUpdateValidationSchema = (isCreation: boolean) => {
  const combinedSchema = baseValidationSchema.merge(passwordValidation);

  return isCreation
    ? combinedSchema.refine(data => data.password === data.confirmPassword, {
        path: ["confirmPassword"],
        message: "As senhas devem coincidir",
      })
    : baseValidationSchema;
};

export const ReportValidationSchema = z.object({
  title: z.string({ required_error: "Campo obrigatório" }).min(1, { message: "Campo obrigatório" }),
  description: z
    .string({ required_error: "Campo obrigatório" })
    .min(1, { message: "Campo obrigatório" }),
  icon: z.string().optional(),
  filters: z.array(
    z.object({
      table: z
        .string({ required_error: "Campo obrigatório" })
        .min(1, { message: "Campo obrigatório" }),
      column: z
        .string({ required_error: "Campo obrigatório" })
        .min(1, { message: "Campo obrigatório" }),
      displayName: z.string().optional(),
    }),
  ),
});

export const createPasswordValidationSchema = () =>
  z
    .object({
      password: z
        .string()
        .min(8, "A senha deve ter pelo menos 8 caracteres")
        .regex(
          REGEX_UPPERCASE_AND_LOWERCASE,
          "A senha deve conter pelo menos uma letra maiúscula e uma minúscula",
        )
        .regex(REGEX_DIGIT, "A senha deve conter pelo menos um número")
        .regex(REGEX_SPECIAL_CHARACTER, "A senha deve conter pelo menos um caractere especial"),

      confirmPassword: z.string().min(1, "A confirmação da senha é obrigatória"),
    })
    .superRefine((data, ctx: any) => {
      if (data.password && data.confirmPassword !== data.password) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["confirmPassword"],
          message: "As senhas não coincidem",
        });
      }
    });

export const AcceptTermsValidationSchema = (
  isPersonalDataRegistered: boolean,
  isPrivacyAccepted: boolean,
) =>
  z.object({
    emailMkt: z.boolean().optional(),
    terms: isPrivacyAccepted ? z.undefined() : z.boolean().refine(value => value === true),
    cpf: isPersonalDataRegistered ? cpfSchema(false) : cpfSchema(true),
    phone: z
      .string()
      .optional()
      .refine(
        value => {
          if (isPersonalDataRegistered) {
            return true;
          }
          return value && REGEX_PHONE.test(value);
        },
        {
          message: "Campo obrigatório e deve ser um número válido",
        },
      ),
  });
