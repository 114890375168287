export class Cookies {
  get(name: string) {
    if (typeof document !== "undefined") {
      const cookieString = `; ${document.cookie}`;
      const parts = cookieString.split(`; ${name}=`);

      if (parts.length === 2) {
        const lastPart = parts.pop();
        if (lastPart) {
          const cookieValue = lastPart.split(";").shift();
          return cookieValue !== undefined ? cookieValue : null;
        }
      }
    }
    return null;
  }

  set(name: string, value: string, expires?: number) {
    let expiresValue = "";

    if (expires) {
      const date = new Date();
      date.setTime(date.getTime() + expires);
      expiresValue += `expires=${date.toUTCString()};`;
    }

    if (typeof document !== "undefined") {
      document.cookie = `${name}=${value};${expiresValue} path=/`;
    }
  }

  delete(name: string) {
    if (typeof document !== "undefined") {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  }
}

export const useCookies = () => new Cookies();
