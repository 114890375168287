import Input from "@src/components/common/Input";
import React, { useState, useEffect, ReactNode } from "react";
import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";

import style from "./labelfieldinput.module.scss";

type InputType = {
  label: string | ReactNode;
  id: string;
  name: string;
  type: string;
  maxLength?: number;
  placeholder: string;
  error: boolean;
  required?: boolean;
  mask?: string;
  errorMessage?: string;
  autoComplete?: string;
  control: any;
  value?: string;
};

const LabelFieldInput: React.FC<InputType> = ({
  label,
  id,
  name,
  type,
  maxLength,
  placeholder,
  error,
  errorMessage,
  required,
  mask,
  autoComplete,
  control,
  value,
}) => {
  const [inputValue, setInputValue] = useState(value || "");

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  return (
    <div className={style.labelfieldinput}>
      <label
        htmlFor={id}
        className="font-inter text-sm font-medium leading-3 tracking-wider text-left"
      >
        {label}
      </label>
      {required && <label>*</label>}
      <Controller
        name={name}
        control={control}
        render={({ field }) =>
          mask ? (
            <InputMask
              {...field}
              mask={mask}
              value={inputValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInputValue(e.target.value);
                field.onChange(e);
              }}
              maskChar={null}
            >
              {(inputProps: any) => (
                <Input
                  {...inputProps}
                  id={id}
                  error={error}
                  type={type}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  autoComplete={autoComplete}
                />
              )}
            </InputMask>
          ) : (
            <Input
              {...field}
              id={id}
              value={inputValue}
              type={type}
              error={error}
              placeholder={placeholder}
              maxLength={maxLength}
              autoComplete={autoComplete}
              onChange={e => {
                setInputValue(e.target.value);
                field.onChange(e);
              }}
            />
          )
        }
      />
      {error && errorMessage && <span className="text-sm text-red-500 mt-1">{errorMessage}</span>}
    </div>
  );
};

export default LabelFieldInput;
