import classNames from "classnames";
import Image from "next/image";
import React, { InputHTMLAttributes, useState } from "react";

const showIcon = <Image src={"/show.svg"} alt="show" width={20} height={20} />;

const hideIcon = <Image src={"/hide.svg"} alt="hide" width={20} height={20} />;

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

function InputPassword(props: Props, ref: React.LegacyRef<HTMLInputElement>) {
  const [show, setShow] = useState(false);

  const { error, ...rest } = props;

  const borderClass = classNames({
    "border-system-error-500": error,
    "border-neutral-900": !error,
  });

  const toggle = () => setShow(current => !current);

  return (
    <div className="relative w-full">
      <div className="absolute right-4 top-2.5 cursor-pointer text-neutral-900" onClick={toggle}>
        {show ? showIcon : hideIcon}
      </div>
      <input
        ref={ref}
        autoComplete="new-password"
        name="unique-password-field"
        id="unique-password-field"
        type={show ? "text" : "password"}
        className={`
          mt-2 border border-solid appearance-none
          block w-full py-3 px-4 leading-tight text-neutral-900 text-[15px] bg-neutral-0
          focus:bg-neutral-100 focus:border-neutral-500 hover:border-neutral-500 rounded-lg focus:outline-none ${borderClass}
        `}
        {...rest}
      />
    </div>
  );
}

export default React.forwardRef(InputPassword);
